@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
option {
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(130, 130, 130);
}
a,
img {
  display: block;
}

input,
select,
textarea,
datalist {
  width: 100%;
  padding: 0.5rem 0.2rem;
  background: transparent;
  font-weight: 400;
  font-size: 14px;
  color: rgb(71, 71, 71);
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  /* border: none !important; */
  /* background: transparent !important; */
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.Container {
  width: 90%;
  margin: 0 auto;
  max-width: 1100px;
  height: 100%;
}

/* Navbar */

.navbar {
  height: 75px;
}

.bar {
  width: 20px;
  background: rgb(77, 77, 77);
  height: 2px;
  margin: 5px 0;
  transition: all 200ms ease-in-out;
}

.nav-active .one {
  transform: rotate(45deg) translateY(5px);
}

.nav-active .two {
  display: none;
}

.nav-active .three {
  transform: rotate(-45deg) translateY(-5px);
}

.nav-links-padding {
  padding-left: 5%;
}

.field-wrapper:focus-within {
  border: 1px solid #6ca4ca;
  box-shadow: 0px 0px 0px 1px rgb(99, 122, 201);
}

.upload-fonts {
  font-size: 10px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:hover,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus {
  padding: 0.5rem 0.2rem !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-width: 0px !important;
  font-size: 14px !important;
  color: rgb(71, 71, 71) !important;
}


#Custom_date_picker{
  position:relative;
}

#Custom_date_picker .MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

#Custom_date_picker .MuiFormControl-root{
     width: 100% !important;
}
#Custom_date_picker .MuiOutlinedInput-notchedOutline{
     border-color:#9f9f9f !important;
}



.BlackTransparentBg {
  background: rgba(0, 0, 0, 0.6) !important;
}
.higher-z-index {
  z-index: 1000;
}


.loginBtn{
box-shadow: 0px 0px 20px rgba(0,0,0,0.05) !important ;
}

.react-datetime-picker__wrapper{
  border:none !important
}

.word-break {
  word-wrap: break-word;
  }